import { combineReducers } from 'redux';

import App from '@redux/app/reducer';
import Course from '@redux/course/reducer';
import Degree from '@redux/degree/reducer';
import Enrollment from '@redux/enrollment/reducer';
import InteractContent from '@redux/interactiveContent/reducer';
import Learning from '@redux/learning/reducer';
import manageUserReducer from '@redux/manageUser/reducer';
import manageUserIdentityReducer from '@redux/manageUserIdentity/reducer';
import University from '@redux/university/reducer';
import User from '@redux/user/reducer';
import userIdentityReducer from '@redux/userIdentity/reducer';

const combinedReducers = combineReducers({
  App,
  Enrollment,
  Course,
  Learning,
  User,
  InteractContent,
  Degree,
  University,
  UserIdentity: userIdentityReducer,
  ManageUser: manageUserReducer,
  ManageUserIdentity: manageUserIdentityReducer,
});

export default combinedReducers;

export type RootState = ReturnType<typeof combinedReducers>;
